const Global = {
  REQUEST_HEADER_KEYS: {
    TOKEN: "x-auth-token",
    CONTENT_TYPE: "Content-Type",
  },
}

export default Global

export const GOOGLE_CLIENT_ID = "407293162806-8inl2eki0ivbp8c6dtc4rfealgfl8dsu.apps.googleusercontent.com"

export const FACEBOOK_APP_ID = "399716761033504"
export const FACEBOOK_APP_SECRET = "964bf575176f2d9bca661f058466a925"

export const USER_DATA_KEY_IN_STORAGE = "USER_DATA"

export const SHOPTYPE_TRIGGER_USER_EVENT_SCRIPT_URL = "https://shoptype-scripts.s3.amazonaws.com/triggerUserEvent.js"

export const SHOPTYPE_CHECKOUT_URL = 'https://92e4d4c2aa.nxcli.net/checkout/'

export const MEDIA_PROXY_URL = "https://enigmatic-beyond-40946.herokuapp.com/"