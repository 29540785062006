import React from 'react'
import "./styles.scss";

class ImageLoader extends React.Component {
    constructor() {
        super();
        this.state = { loaded: false };
    }

    render() {
        return (
            <React.Fragment>
                {this.state.loaded ? null :
                    <div className="loading-background" />
                }
                <img
                    style={this.state.loaded ? {} : { display: 'none' }}
                    {...this.props}
                    onLoad={() => this.setState({ loaded: true })}
                    alt=""
                />
            </React.Fragment>
        );
    }
}

export default ImageLoader;