import React, { useEffect } from "react";
import "./Header.scss";
import { useDispatch, useSelector } from "react-redux";
import SearchCustomModal from "../search/searchCustomModal";
import { NavLink } from "react-router-dom";
import { USER_DATA_KEY_IN_STORAGE } from "../../constants/global";
import { LOGIN_SUCCESS, DEVICE_ID_LOADED } from "../../actions/actionTypes";
import { searchModalOpen } from "../../actions/actions";
import { SHOPTYPE_TRIGGER_USER_EVENT_SCRIPT_URL } from "../../constants/global";
import appendScript from "../../helpers/appendScript";

export default () => {
  const dispatch = useDispatch();
  const searchModalOpened = useSelector(
    (state) => state.modal.searchModalOpened
  );
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  useEffect(() => {
    // Check user login status
    let userData = localStorage.getItem(USER_DATA_KEY_IN_STORAGE);
    if (userData) {
      userData = JSON.parse(userData);
      dispatch({
        type: LOGIN_SUCCESS,
        payload: userData,
      });
    }
    loadScriptForShoptypeUserEvent();
  }, []);

  const loadScriptForShoptypeUserEvent = () => {
    appendScript(SHOPTYPE_TRIGGER_USER_EVENT_SCRIPT_URL)
      .then(() => {
        // perform actions after script load
        window.getDeviceId().then((deviceId) => {
          dispatch({
            type: DEVICE_ID_LOADED,
            payload: deviceId,
          });
        });
      })
      .catch(() =>
        console.log("unable to load shoptype trigger user event script")
      );
  };

  return (
    <div>
      <nav
        className="navbar fixed-top navbar-expand-lg navbar-light categories-section"
        position="static"
        style={{
          maxHeight: "80px",
          borderRadius: "0px 0px 15px 15px",
          background: "#FF3A5E",
          padding: "20px",
        }}
      >
        <div className="container center">
          {/* <div className="col-2">
            <NavLink to="/communities">
              <img
                className="header-img"
                src={require("../../assets/images/Communities.svg")}
                alt="communities-img"
              />
            </NavLink>
          </div> */}

          <div className="col-2">
            {isLoggedIn ? (
              <img
                src={require("../../assets/images/Search.svg")}
                alt="search-img"
                onClick={() => dispatch(searchModalOpen())}
              />
            ) : (
              ""
            )}
          </div>

          <div className="col-8 logo-section-header text-center">
            <NavLink to="/">
              <img
                className={`${isLoggedIn ? "logo-img" : "logo-img-small"}`}
                src={require("../../assets/images/Sheroes-HeaderLabel.svg")}
                alt="sheroes"
              />
            </NavLink>
          </div>
          <div className="col-2">
            {isLoggedIn ? (
              <NavLink
                to="/cart"
                className="header-link"
                activeClassName="header-active-link"
              >
                <img
                  src={require("../../assets/images/Cart.svg")}
                  alt="cart-img"
                />
                <div className="header-active-link-bg" />
              </NavLink>
            ) : (
              ""
            )}
          </div>
          {/* <div className="col-2">
            <img
              className="header-img"
              src={require("../../assets/images/Profile-Icon.svg")}
              alt="profile-img"
              onClick={() => dispatch(modalOpen())}
            />
          </div> */}
        </div>
      </nav>
      {/* modal */}
      {searchModalOpened && <SearchCustomModal />}
      {/* {isLoginModalOpen && <LoginModal />} */}
    </div>
  );
};
