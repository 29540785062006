import CustomCarousel from "./customCarousel";
import React, { useState } from "react";
import VideoModal from "./modal";

const VideoCarousel = () => {
  let [showModal, setShowModal] = useState(false);
  let [video, setVideo] = useState({});
  const listOfVideos = [
    {
      name: "What is SHECO?",
      image:
        "https://img.sheroes.in/img/uploads/sheroes/global/page__202007221159368996.jpeg",
      url: "https://www.youtube.com/embed/JNZBcsifhSk",
    },
    {
      name: "How to join SHECO?",
      image:
        "https://img.sheroes.in/img/uploads/sheroes/global/page__202007221201039633.jpeg",
      url: "https://www.youtube.com/embed/lhKTv7KTQfo",
    },
    // {
    //   name: "How to choose your products?",
    //   image:
    //     "https://img.sheroes.in/img/uploads/sheroes/global/page__201911290920308977.jpeg",
    //   url: "https://www.youtube.com/embed/JNZBcsifhSk",
    // },
    // {
    //   name: "How to make your first sale?",
    //   image:
    //     "https://img.sheroes.in/img/uploads/sheroes/global/page__201911290922021032.jpeg",
    //   url: "https://www.youtube.com/embed/JNZBcsifhSk",
    // },
  ];

  function handleClick(videoObj) {
    setShowModal(true);
    setVideo(videoObj);
  }

  return (
    <div>
      <div
        style={{
          backgroundColor: "#F7F7F7",
          paddingTop: "25px",
        }}
        className="p-4 section-title gray29"
      >
        Become an ace Entrepreneur
      </div>
      <CustomCarousel
        handleClick={handleClick}
        type="video"
        items={listOfVideos}
      />
      <VideoModal
        video={video}
        setShowModal={setShowModal}
        showModal={showModal}
      />
    </div>
  );
};

export default VideoCarousel;
