import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  FETCHED_PRODUCT_CATEGORIES,
  FETCHING_PRODUCT_CATEGORIES,
} from "../../actions/actionTypes";
import { BASE_API_URL } from "../../constants/api";
import { getProductCollections } from "../../lib/api";
import "./ProductCategoriesSection.scss";
import ImageLoader from "../../components/image-loader";
import Loader from "../loader";

export default ({ isLoggedIn }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const categories = useSelector((state) => state.products.productCategories);
  const [categoriesLoading, setCategoriesLoading] = useState(true);

  useEffect(() => {
    dispatch({ type: FETCHING_PRODUCT_CATEGORIES });
    getProductCollections("sheroes-products-collection").then((res) => {
      const categories = res.data[0] ? res.data[0]["product_categories"] : [];
      setCategoriesLoading(false);
      dispatch({
        type: FETCHED_PRODUCT_CATEGORIES,
        payload: categories,
      });
    });
  }, []);

  const handleCategoryClick = (e, item) => {
    e.preventDefault();
    if (isLoggedIn) {
      history.push(`/products/?category=${item.slug}`);
    }
  };

  return (
    <div className="pt25 pb25">
      <div className="mb25 section-title-container">
        <div className="section-title gray29">SHECO Catalog for you</div>
        {/* <div className="">
          <p className="footer-link coral-red mb0">View all</p>
        </div> */}
      </div>
      {categoriesLoading ? (
        <Loader size={"60px"} />
      ) : (
        <div className="categories-wrapper">
          {categories.map((item, index) => (
            <div
              className={
                index === 0
                  ? "category-box col-11 m10"
                  : "category-box col-5 m10 pl10 pr10"
              }
              key={index}
              onClick={(e) => handleCategoryClick(e, item)}
            >
              {item.image && (
                <ImageLoader
                  src={`${BASE_API_URL}${item.image.url}`}
                  alt={item.image.alternativeText}
                />
              )}
              <p>{item.title}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
