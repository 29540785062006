import React from "react";
import "./SellSheco.scss";

export default () => {

  const features = [
    {
      title: "Work from home with zero investment",
    },
    {
      title: "Earn High Margines",
    },
    {
      title: "Get exclusive shop on your profile",
    },
    {
      title: "Chats directly with chats and customers",
    },
    {
      title: "Access to product training and learning tools",
    },
    {
      title: "Full and constant support",
    }
  ]
  return (
    <div className="categories-section pt25 pb25">
      <div className="section-title white mb20"  style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}>Sheco for YOU!</div>
      <div className="list-style" >
        {
          features.map(item => (
            <div className="list-item" >
              <img src={require('../../assets/images/female-sign.svg')} alt="" />
              <span>{item.title}</span>
            </div>
          ))
        }
      </div>
    </div>
  );
};
