import React, { Component } from "react";
import { connect } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";

import { white, lightGrey } from "../../colors/color";
import "./Cart.scss";
import {
  getCartDetail,
  productCheckoutAction,
  removeItemFromCart,
  updateProductQuantityInCart,
  registerUserEvent,
} from "../../lib/api";
import {
  SHOPTYPE_CHECKOUT_URL,
} from "../../constants/global";
import Loader from "../../components/loader";

const styles = {
  singleProductMainBody: {
    height: window.innerHeight - 65,
    // overflowY: "scroll",
    paddingBottom: "30vh",
  },
  title4VHText: { fontSize: "4vh", textAlign: "center" },
  footerContainer: {
    height: "16vh",
    background: "#FF3A5E",
    position: "fixed",
    bottom: "0px",
    width: "100%",
    borderRadius: "15px 15px 0 0",
    left: "0",
  },
  footerContainerChild: {
    padding: "26px 46px 0px 46px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  joinTheCosellerContainer: (cosellClicked) => ({
    height: cosellClicked ? "6vh" : "14vh",
    display: "flex",
    flexDirection: "column",
    position: "fixed",
    bottom: "16vh",
    width: "100%",
    background: white,
  }),
  joinCosellerChild: (cosellClicked) => ({
    padding: cosellClicked ? "0px 30px" : "4px 30px 4px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
    width: "100%",
  }),
  joinTheCosellertext: {
    fontSize: "2.4vh",
    fontWeight: "bold",
    color: "#FF3A5E",
  },
  expandLessContainer: {
    width: "26px",
    height: "26px",
    background: "#FF3A5E",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: white,
  },
  becomeCosellerContainer: {
    padding: "0px 30px 0px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  becomeCosellerTextDiv: {
    fontSize: "2.4vh",
    display: "flex",
    flexDirection: "column",
  },
  topImageContainer: {
    position: "relative",
    background: "#a9a9a966",
    height: "30vh",
    padding: "0px 20p",
    marginLeft: "22px",
    marginRight: "22px",
  },
  topImgLeftRightIconContainer: {
    position: "absolute",
    top: "20%",
    width: "100%",
    padding: "0px 8px",
    display: "flex",
    justifyContent: "space-between",
  },
  topImgBottomImgContainer: {
    position: "absolute",
    bottom: "0px",
    height: "12vh",
    width: "100%",
    display: "flex",
    alignItems: "center",
    padding: "0px 16px",
  },
  topImgBottomChildImg: {
    width: "20vw",
    background: lightGrey,
    height: "8vh",
    borderRadius: "4px",
    margin: "4px",
  },
  viewButtonContainer: {
    display: "flex",
    justifyContent: "space-between",
    padding: "18px 22px 0px 22px",
    alignItems: "center",
  },
  productTitleContainer: {
    display: "flex",
    flexDirection: "column",
    lineHeight: "4vh",
    margin: "0px 22px",
  },
  sizeSpan: {
    width: "50%",
    background: "#9e9e9e70",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  sizeSpanRight: {
    width: "20%",
    background: "#F44336",
    borderRadius: "1px 4px 4px 1px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
  },
  colourSpan: {
    width: "50%",
    background: "#ffc10769",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "5px",
    borderRadius: "4px",
  },
  colourSpanRight: {
    width: "20%",
    background: "#F44336",
    borderRadius: "1px 4px 4px 1px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
  },
  quantitySpanLeft: {
    width: "20%",
    background: "#9E9E9E",
    borderRadius: "4px 1px 1px 4px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
  },
  quantitySpanMiddle: {
    width: "30%",
    background: "#9e9e9e70",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  quantitySpanRight: {
    width: "20%",
    background: "#F44336",
    borderRadius: "1px 4px 4px 1px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
  },
};

class CartProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      activeStep: 0,
      list: [],
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  removeItemFromCart = (item) => {
    removeItemFromCart(item)
      .then((res) => this.fetchData())
      .catch((err) => console.log("error removing item from cart: ", err));
  };

  addVariantDetails = () => { };

  fetchData = () => {
    this.setState({ loading: true });
    getCartDetail()
      .then((res) => {
        this.setState(
          (state) => ({
            ...state,
            loading: false,
            list: res.data ? res.data.items : [],
            cartId: res.data ? res.data._id : null,
          }),
          this.addVariantDetails()
        );
      })
      .catch((err) => {
        console.log("get cart error: ", err);
      });
  };

  cartItemCard = (cartItem, classes) => {
    const { product, productId, variantId, orderQuantity } = cartItem;
    if (!product) {
      return <></>;
    }
    const selectedVariant = product.variants.find((item) => {
      return item.id === variantId;
    });
    const selectedVariantDetails = selectedVariant ? selectedVariant.variantNameValue : null;

    console.log(selectedVariantDetails, "variant...");

    return (
      <div key={`${productId}-${variantId}`} className="cart-container1">
        { product && product.primaryImageSrc ? (
          <div className="cart-img">
            <img
              src={product.primaryImageSrc.imageSrc}
              alt={product.slug}
              className="cart-thumbnail"
            />
          </div>
        ) : null}
        <div className="cart-img-text">{product.title}</div>
        <DeleteOutline
          className="cart-delete-icon"
          onClick={() => this.removeItemFromCart(cartItem)}
        />
        {/* <div className="cart-img-text1">{ product.description }</div> */}

        {selectedVariant && (
          <>
            <div className="cart-amount">Total Amount</div>
            <div className="cart-amount-right">
              {product.currency}
              {selectedVariant.discountedPrice}
            </div>
          </>
        )}
        <div
          style={{
            position: "relative",
            top: "190px",
            marginLeft: "14px",
            marginRight: "14px",
            width: "90%",
          }}
          className="row"
        >
          {/* display the variant details here */}
          {selectedVariantDetails
            ? Object.keys(selectedVariantDetails).map((key, index) => {
              return (
                <div className="col-6" key={index}>
                  <p className="mb-0 bold uppercase">{key}: </p>
                  <p>{selectedVariantDetails[key]}</p>
                </div>
              );
            })
            : ""}
          <div className="col-4">
            <span style={{ fontWeight: "bold", paddingLeft: "24%" }}>
              QUANTITY:
            </span>
            <div
              style={{
                display: "flex",
                height: "3.5vh",
                justifyContent: "flex-end",
              }}
            >
              <span className={classes.quantitySpanLeft}>
                <RemoveIcon
                  onClick={() =>
                    this.changeOrderQuantity(cartItem, --cartItem.orderQuantity)
                  }
                />
              </span>
              <span className={classes.quantitySpanMiddle}>
                {orderQuantity}
              </span>
              <span className={classes.quantitySpanRight}>
                <AddIcon
                  onClick={() =>
                    this.changeOrderQuantity(cartItem, ++cartItem.orderQuantity)
                  }
                />
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  changeOrderQuantity = (item, orderQuantity) => {
    if (orderQuantity) {
      updateProductQuantityInCart({
        ...item,
        orderQuantity,
      })
        .then((res) => this.fetchData())
        .catch((err) => console.log("error while changing order quantity"));
    } else {
      this.removeItemFromCart(item);
    }
  };

  getTotalCartValue() {
    const { list } = this.state;

    let amount = 0;

    for (let item of list) {
      const { variantId, product, orderQuantity } = item;
      const selectedVariant = product.variants.find(variant => variant.id === variantId)
      amount += (selectedVariant ? selectedVariant.discountedPrice : 0) * orderQuantity;
    }
    return amount;
  }

  handleCheckout = () => {
    const shoptypeData = this.state.list[0].product;
    const productId = shoptypeData.id;
    const vendorId = shoptypeData.catalogId;
    const checkoutId = uuidv4();

    let { deviceId } = this.props.auth
    if (!deviceId) {
      deviceId = localStorage.getItem("deviceId");
    }
    this.registerUserEventClick({
      deviceId,
      productId,
      vendorId,
      checkoutId,
      action: "product_checkout",
      url: window.location.href,
      referrer: window.location.href,
    });

    productCheckoutAction({
      deviceId,
      productId,
      vendorId,
      checkoutId,
    })
      .then((res) => {
        let products = "";
        for (let product of this.state.list) {
          products += `${product.variantId}:${product.orderQuantity},`;
        }
        window.location.href = `${SHOPTYPE_CHECKOUT_URL}?add-to-cart=${products}&checkout_id=${checkoutId}`;
      })
      .catch((err) => console.log("checkout api error: ", err));
  };

  registerUserEventClick(payload) {
    registerUserEvent(payload).then((res) => {
      console.log(res, "register res..");
    });
  }

  render() {
    const { classes } = this.props;
    const { list } = this.state;

    return (
      <div
        className="container"
        style={{ height: "100%", marginBottom: "150px" }}
      >
        {this.state.loading ? (
          <div style={{ marginTop: "300px" }}>
            <Loader />
          </div>
        ) : list.length > 0 ? (
          <React.Fragment>
            <div className="cart-container">
              <div className="row">
                <p className="cart-style col-12">Cart</p>
              </div>
              {/* cart overview */}
              {/* <div>
            <div style={{
              position: "absolute",
              width: "72px",
              height: "21px",
              left: "32px",
              top: "85px",
              fontFamily: "SF Pro Text",
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: "18px",
              lineHeight: "21px",
              color: "#373737"
            }}>Subtotal</div>
            <div style={{
              position: "absolute",
              width: "81px",
              height: "21px",
              left: "301px",
              top: "85px",
              fontFamily: "SF Pro Text",
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: "18px",
              lineHeight: "21px",
              color: "#373737",
              textAlign: "right"
            }}>3500 INR</div>
          </div>

          <div>
            <div style={{
              position: "absolute",
              width: "116px",
              height: "21px",
              left: "32px",
              top: "105px",
              fontFamily: "SF Pro Text",
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: "18px",
              lineHeight: "21px",
              color: "#373737"
            }}>Taxes Applied</div>
            <div style={{
              position: "absolute",
              width: "58px",
              height: "21px",
              left: "324px",
              top: "105px",
              fontFamily: "SF Pro Text",
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: "18px",
              lineHeight: "21px",
              color: "#373737",
              textAlign: "right"
            }}>50 INR</div>
          </div>
          <div>
            <div style={{
              position: "absolute",
              width: "70px",
              height: "17px",
              left: "32px",
              top: "130px",
              fontFamily: "SF Pro Text",
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: "14px",
              lineHeight: "17px",
              color: "#818181"
            }}>(18% GST)</div>
          </div>
          <div>
            <div style={{
              position: "absolute",
              width: "188px",
              height: "29px",
              left: "32px",
              top: "160px",
              fontFamily: "SF Pro Text",
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: "24px",
              lineHeight: "29px",
              color: "#FF6969"
            }}>Estimated Total</div>
            <div style={{
              position: "absolute",
              width: "124px",
              height: "29px",
              left: "258px",
              top: "160px",
              fontFamily: "SF Pro Text",
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: "24px",
              lineHeight: "29px",
              color: "#FF6969",
              textAlign: "right"
            }}>3550 INR</div>
          </div> */}
            </div>

            {list.map((item, index) => this.cartItemCard(item, classes))}

            <div className={classes.footerContainer}>
              <div className={classes.footerContainerChild}>
                {list && list.length && (
                  <span
                    style={{
                      fontSize: "24px",
                      fontWeight: "bold",
                      color: "white",
                    }}
                  >
                    {list[0].product.currency} {this.getTotalCartValue()}
                  </span>
                )}
                <Button
                  variant="contained"
                  style={{
                    padding: "12px 22px",
                    color: "#4A4A4A",
                    backgroundColor: white,
                    fontWeight: "700",
                    fontSize: "18px",
                    textTransform: "uppercase",
                  }}
                  onClick={this.handleCheckout}
                >
                  checkout
                </Button>
              </div>
            </div>
          </React.Fragment>
        ) : (
              <div>
                <p
                  style={{
                    color: "#BFBFBF",
                    marginTop: "300px",
                    textAlign: "center",
                  }}
                >
                  <img
                    style={{
                      height: "75px",
                      width: "75px",
                      filter: "invert(0.25)",
                      marginBottom: "15px",
                    }}
                    src={require("../../assets/images/Cart.svg")}
                    alt="cart-img"
                  />
                  <p>
                    <b>Your cart is empty</b>
                  </p>
                </p>
              </div>
            )}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(withStyles(styles)(CartProduct));
