/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import { Typography, Box } from "@material-ui/core";

const VideoDetail = ({ video }) => {
  return (
    <React.Fragment>
      <Box>
        <Box className="p-1">
          <Typography>{video.name}</Typography>
          {
            // eslint-disable-next-line jsx-a11y/iframe-has-title
          }
          <iframe
            width="100%"
            height="275"
            src={video.url}
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </Box>
      </Box>
    </React.Fragment>
  );
};
export default VideoDetail;
