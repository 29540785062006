import React, { useState, useEffect } from "react";
import "./Filter.scss";
import MenuItemComponent from "./MenuItemComponent";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";

const Filter = ({
  options,
  selectedFilter,
  handleFilterSelect,
  handleQuantityChange,
  currentVariantAvailableQty,
  initialQuantity,
}) => {
  const [quantity, setQuantity] = useState(0);
  const [singleFilterOption, setSingleFilterOption] = useState(false);

  useEffect(() => {
    setQuantity(initialQuantity);
  }, [initialQuantity]);

  useEffect(() => {
    handleQuantityChange(quantity);
    if (options.length === 1 && options[0].values.length === 1) {
      setSingleFilterOption(true);
    }
  }, [quantity]);

  const filterOptionClicked = (key, value) => {
    const obj = {};
    obj[key] = value;
    // Reset quantity
    setQuantity(1);
    handleFilterSelect({ selectedFilters: obj });
  };

  const changeOrderQuantity = (d) => {
    if (d === 1) {
      if (quantity >= currentVariantAvailableQty) {
        console.log(quantity);
        return;
      }
      setQuantity(quantity + 1);
    } else if (d === -1) {
      if (quantity === 0) {
        return;
      }
      setQuantity(quantity - 1);
    }
  };

  return (
    <div className="container row">
      {!singleFilterOption &&
        options.length > 0 &&
        options.map((item, index) => (
          <div className="col-6" key={index}>
            {/* dropdown menu */}
            <div className="flex flex-column">
              <span
                style={{
                  fontWeight: "bold",
                  fontSize: "1.7vh",
                  paddingLeft: "10px",
                  textTransform: "uppercase",
                }}
              >
                {item.name}:
              </span>
              <MenuItemComponent
                menuValues={item.values}
                selectedFilterValue={selectedFilter[item.name]}
                filterOptionClicked={(value) =>
                  filterOptionClicked(item.name, value)
                }
              />
            </div>
          </div>
        ))}
      {/* quantity */}
      <div className="col-6">
        <span
          style={{
            fontWeight: "bold",
            fontSize: "1.7vh",
            paddingLeft: "10px",
            textTransform: "uppercase",
          }}
        >
          quantity:
        </span>
        <div
          style={{
            display: "flex",
            height: "34px",
            paddingLeft: "12px",
          }}
        >
          <span className="quantitySpanLeft">
            <RemoveIcon onClick={() => changeOrderQuantity(-1)} />
          </span>
          <span className="quantitySpanMiddle">{quantity}</span>
          <span className="quantitySpanRight">
            <AddIcon onClick={() => changeOrderQuantity(1)} />
          </span>
        </div>
      </div>
    </div>
  );
};

export default Filter;
