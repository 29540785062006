import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import MobileStepper from "@material-ui/core/MobileStepper";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import Fab from "@material-ui/core/Fab";
import { darkRed, white, lightGrey } from "../../colors/color";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import TopNavbar from "../../components/top-navbar/TopNavbar";
import {
  addProductToMyShop,
  getProductCollections,
  getProducts,
} from "../../lib/api";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ProductCard from "../../components/product-card";
import InfiniteScroll from "react-infinite-scroll-component";
import { toast } from "react-toastify";
import Loader from "../../components/loader";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const styles = {
  shopCardContainer: {
    padding: "12px 22px",
    width: "100%",
  },
  imgStyle1: {
    height: "3vh",
    width: "auto",
    cursor: "pointer",
  },
  productHomeContainer: {
    height: window.innerHeight - 65,
    marginTop: "18vh",
    paddingLeft: "6px",
    paddingRight: "6px",
    paddingTop: "10px",
    paddingBottom: "10px",
    marginLeft: "15px",
    marginRight: "15px",
  },
  imgStyle: {
    height: "21vh",
    width: "100%",
    objectFit: "contain",
    borderRadius: "10px",
  },
  mobileStepper: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  titleTextStyle: {
    color: lightGrey,
    textAlign: "left",
    paddingLeft: "10px",
    fontWeight: "bold",
    marginBottom: "2px",
  },
  onOffButtonContainer: {
    display: "flex",
    padding: "0px 20px",
    justifyContent: "space-between",
  },
  onButton: {
    width: "50%",
    borderRadius: "20px 0px 0px 20px",
    height: "34px",
    backgroundColor: darkRed,
    color: white,
    fontWeight: "bold",
  },
  offButton: {
    width: "50%",
    borderRadius: "0px 20px 20px 0px",
    height: "34px",
    backgroundColor: lightGrey,
    color: white,
    fontWeight: "bold",
  },
  iconStyle: { height: "1.2em", width: "1.2em" },
  productTypeContainer: {
    display: "flex",
    // padding: "0px 20px",
    justifyContent: "space-between",
    marginTop: "15px",
  },
  productListContainer: {
    width: "92%",
    height: "50vh",
    margin: "2%",
  },
  productListImageContainer: {
    height: "64%",
    background: lightGrey,
    position: "relative",
    borderRadius: "5px",
    display: "flex",
    justifyContent: "center",
  },
  productListPriceContainer: {
    position: "absolute",
    bottom: "0px",
    left: "23%",
    width: "56%",
    background: darkRed,
    height: "24%",
    borderRadius: "10px 10px 0px 0px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: white,
    fontWeight: "bold",
  },
  productInfoContainer: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
  },
  autoPlayContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
};

class ProductsCarousal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      list: [],
      carousalProducts: [],
      start: 0,
      maxItemsToShow: 5,
      hasMore: true,
      categories: [
        {
          title: "All Products",
          slug: "sheroes-product-corousal",
        },
      ],
      selectedCategorySlug: "",
      anchorEl: null,
    };
  }

  componentDidMount() {
    this.fetchInitData();
    this.fetchCollections();
  }

  fetchInitData = () => {
    let categorySlug = this.getCategorySlug();

    this.setState((state) => ({
      ...state,
      selectedCategorySlug: categorySlug,
    }));

    // Need two parallel API calls here, one for sheroes-product-carousel, second for the selected category
    Promise.all([
      getProducts({
        categorySlug: "sheroes-product-corousal",
        limit: this.state.maxItemsToShow,
      }),
      getProducts({ categorySlug, limit: this.state.maxItemsToShow }),
    ])
      .then(([carousalResponse, productsByCategoryResponse]) => {
        this.setState(() => ({
          ...this.state,
          selectedCategorySlug: categorySlug,
          carousalProducts: carousalResponse.data
            ? carousalResponse.data.slice(0, this.state.maxItemsToShow)
            : [],
          list: productsByCategoryResponse.data || [],
          hasMore:
            productsByCategoryResponse.data.length < this.state.maxItemsToShow
              ? false
              : true,
        }));
      })
      .catch((err) => console.log("promise all error: ", err));
  };

  fetchCollections = () => {
    getProductCollections("sheroes-products-collection").then((res) => {
      const categories = res.data[0]
        ? res.data[0].product_categories
        : this.state.categories;
      this.setState(() => ({
        ...this.state,
        categories: [...this.state.categories, ...categories],
      }));
    });
  };

  fetchProductsForCategory = (slug) => {
    getProducts({ categorySlug: slug })
      .then((res) => {
        this.setState(() => ({
          ...this.state,
          list: res.data || [],
          hasMore: res.data.length < this.state.maxItemsToShow ? false : true,
        }));
      })
      .catch((err) => console.log("getProductCategories error: ", err));
  };

  getSelectedCategoryTitle = () => {
    const category = this.state.categories.find(
      (item) => item.slug === this.state.selectedCategorySlug
    );
    return category ? category.title : "All Products";
  };

  handleBack = () => {
    this.setState((prevState) => ({
      activeStep: prevState.activeStep - 1,
    }));
  };

  handleStepChange = (activeStep) => {
    this.setState({ activeStep });
  };

  handleCategoryButtonClick = (event) => {
    this.setState(() => ({
      ...this.state,
      anchorEl: event.currentTarget,
    }));
  };

  handleClose = (category) => {
    this.setState(() => ({
      ...this.state,
      anchorEl: null,
      selectedCategorySlug: category ? category.slug : "",
    }));
    this.fetchProductsForCategory(category.slug);
  };

  handleAddProductToMyShop = (product) => {
    addProductToMyShop(product)
      .then((res) => {
        toast.success("Item added to your shop");
        console.log("product MyShop response: ", res.data);
      })
      .catch((err) => {
        toast.error("Something went wrong! Please try again.");
        console.log("add to shop error: ", err);
      });
  };

  getCategorySlug() {
    let categorySlug = new URLSearchParams(this.props.location.search).get(
      "category"
    );
    return categorySlug || "";
  }

  // Called by Infinite Scroll
  fetchMoreProducts = () => {
    let categorySlug = this.state.selectedCategorySlug;
    if (!categorySlug) {
      categorySlug = this.getCategorySlug();
    }

    this.setState((state) => ({
      ...state,
      selectedCategorySlug: categorySlug,
      start: state.start + state.maxItemsToShow,
    }));
    getProducts({
      categorySlug: this.state.selectedCategorySlug,
      start: this.state.start,
      limit: this.state.maxItemsToShow,
    })
      .then((res) => {
        if (res.data.length < this.state.maxItemsToShow) {
          this.setState({
            ...this.state,
            hasMore: false,
            list: [...this.state.list, ...res.data],
          });
        } else {
          this.setState(() => ({
            ...this.state,
            list: [...this.state.list, ...res.data],
          }));
        }
      })
      .catch((err) => console.log("getProductCategories error: ", err));
  };

  navigateToProductPage = (product) => {
    this.props.history.push(`/products/${product.id}`);
  };

  render() {
    const { activeStep } = this.state;
    const { classes } = this.props;
    const maxSteps =
      this.state.carousalProducts.length < this.state.maxItemsToShow
        ? this.state.carousalProducts.length
        : this.state.maxItemsToShow;

    return (
      <div>
        <TopNavbar />

        <div className={classes.productHomeContainer}>
          <AutoPlaySwipeableViews
            index={activeStep}
            onChangeIndex={this.handleStepChange}
            enableMouseEvents
          >
            {/* {this.state.carousalProducts &&
              this.state.carousalProducts.length &&
              this.state.carousalProducts
                .slice(0, this.state.maxItemsToShow)
                .map((step, index) => (
                  <div
                    className={classes.autoPlayContainer}
                    key={index}
                    onClick={() => this.navigateToProductPage(step)}
                  >
                    {Math.abs(activeStep - index) <= 2 ? (
                      <img
                        className={classes.imgStyle}
                        src={step.shoptypeData.primaryImageSrc.imageSrc}
                        alt={step.title}
                      />
                    ) : null}
                  </div>
                ))} */}
            {this.state.carousalProducts &&
            this.state.carousalProducts.length &&
            this.state.carousalProducts ? (
              this.state.carousalProducts
                .slice(0, this.state.maxItemsToShow)
                .map((step, index) => (
                  <div
                    className={classes.autoPlayContainer}
                    key={index}
                    onClick={() => this.navigateToProductPage(step)}
                  >
                    {Math.abs(activeStep - index) <= 2 ? (
                      <img
                        className={classes.imgStyle}
                        src={step.shoptypeData.primaryImageSrc.imageSrc}
                        alt={step.title}
                      />
                    ) : null}
                  </div>
                ))
            ) : (
              <div></div>
            )}
          </AutoPlaySwipeableViews>
          <MobileStepper
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            className={classes.mobileStepper}
          />
          <div className={classes.productTypeContainer}>
            <div
              style={{
                width: "48%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Fab
                className={classes.productDropDown}
                variant="extended"
                style={{
                  width: "100%",
                  height: "34px",
                  backgroundColor: "#ff4343",
                  color: white,
                  fontWeight: "bold",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  fontSize: "smaller",
                }}
                onClick={this.handleCategoryButtonClick}
              >
                <span style={{ fontSize: "smaller" }}>
                  {this.getSelectedCategoryTitle()}
                </span>
                <ArrowDropDown className={classes.iconStyle} />
              </Fab>
              <Menu
                id="simple-menu"
                anchorEl={this.state.anchorEl}
                keepMounted
                open={Boolean(this.state.anchorEl)}
                onClose={this.handleClose}
              >
                {this.state.categories.map((item) => (
                  <MenuItem
                    key={item.slug}
                    onClick={() => this.handleClose(item)}
                  >
                    {item.title}
                  </MenuItem>
                ))}
                {/* <MenuItem onClick={handleClose}>My account</MenuItem>
                <MenuItem onClick={handleClose}>Logout</MenuItem> */}
              </Menu>
            </div>
            <div
              style={{
                width: "48%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Fab
                variant="extended"
                style={{
                  width: "100%",
                  height: "34px",
                  backgroundColor: "#ff4343",
                  color: white,
                  fontWeight: "bold",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  fontSize: "smaller",
                }}
              >
                <span style={{ fontSize: "smaller" }}> Highly Rated</span>
                <ArrowDropDown className={classes.iconStyle} />
              </Fab>
            </div>
          </div>
          {/* products list */}
          <div
            style={{
              display: "flex",
              //  padding: "0px 20px",
              paddingTop: "10px",
              paddingBottom: "10px",
              flexWrap: "wrap",
            }}
          >
            <InfiniteScroll
              dataLength={this.state.list.length}
              next={this.fetchMoreProducts}
              hasMore={this.state.hasMore}
              loader={
                <div style={{ paddingTop: "15vh" }}>
                  <Loader />
                </div>
              }
              endMessage={
                this.state.list.length > 0 ? (
                  <span></span>
                ) : (
                  <p
                    style={{
                      color: "#BFBFBF",
                      marginTop: "150px",
                      textAlign: "center",
                    }}
                  >
                    <img
                      style={{
                        height: "75px",
                        width: "75px",
                        filter: "invert(0.25)",
                        marginBottom: "15px",
                      }}
                      src={require("../../assets/images/Search.svg")}
                      alt="magnifier-img"
                    />
                    <p>
                      <b>No products found</b>
                    </p>
                  </p>
                )
              }
            >
              {this.state.list.map((item, index) => (
                <ProductCard
                  product={item}
                  key={index}
                  addProductToMyShop={this.handleAddProductToMyShop}
                />
              ))}
            </InfiniteScroll>
          </div>
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(ProductsCarousal);
