import React, { useEffect } from "react";
import ProductCategoriesSection from "../../components/product-categories-section/ProductCategoriesSection";
import HomeCarousal from "../../components/home-carousal/HomeCarousal";

import "./Home.scss";
import SellSheco from "../../components/sell-sheco/SellSheco";
import FAQ from "../../components/FAQ/FAQ";
import { useDispatch, useSelector } from "react-redux";
import TopNavbar from "../../components/top-navbar/TopNavbar";
import {
  FETCHED_HOME_DATA,
  FETCH_HOME_PAGE_DATA,
} from "../../actions/actionTypes";
import { getSections } from "../../lib/api";
import ArticlesCarousel from "../articles/articlesCarousel";
import VideoCarousel from "../videos/videoCarousel";

export default () => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const dispatch = useDispatch();
  // const section = useSelector(state => state.home.sections && state.home.sections.length
  //   && state.home.sections.find(item => item.slug === slug.HOME_PAGE))
  const section = useSelector((state) => state.home.sections);
  useEffect(() => {
    dispatch({ type: FETCH_HOME_PAGE_DATA });

    getSections().then((res) => {
      console.log(res);
      let temp = [];
      if (res.data[0]) {
        res.data[0].collections.forEach((element) => {
          temp.push({ key: element.slug, value: element });
        });
      }
      dispatch({
        type: FETCHED_HOME_DATA,
        payload: temp,
      });
    });
  }, []);
  const getFeatures = (slugkey) => section;

  console.log(getFeatures());
  return (
    <div>
      {isLoggedIn ? (
        <div>
          <TopNavbar />
        </div>
      ) : (
        ""
      )}
      <div>
        <HomeCarousal />
      </div>
      {isLoggedIn ? (
        ""
      ) : (
        <div>
          <SellSheco />
        </div>
      )}
      {/* <div>
        <div ref={videosRef}>
          <VideosSection
            video={getFeatures()}
          />
        </div>
      </div> */}
      <div>
        <ProductCategoriesSection isLoggedIn={isLoggedIn} />
      </div>
      {/* <div>
        <BlogSection />
      </div> */}
      <div>
        <ArticlesCarousel />
      </div>
      <div>
        <VideoCarousel />
      </div>
      <div>
        <FAQ />
      </div>
      {/* <div>
        <Footer />
      </div> */}
    </div>
  );
};
