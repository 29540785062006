import {
  BASE_API_URL,
  SECTIONS_API,
  STORIES_API,
  SHEROES_MIDDLEWARE_BASE_PATH,
  PARTNER_AUTH_API,
  PRODUCT_CATEGORIES_API,
  PRODUCT_COLLECTIONS_API,
  PRODUCTS_API,
  PRODUCTS_CART_API,
  STORY_COLLECTIONS_API,
  PRODUCT_WISHLIST_API,
  PRODUCT_CHECKOUT_API,
  LOGIN_API,
  USER_EVENT_API,
} from "../constants/api";
import { USER_DATA_KEY_IN_STORAGE } from "../constants/global";
import { getUrlQueryParamsPrefix } from "../helpers/utils";
import HttpClient from "../services/http-client";

export const getAuthToken = () => {
  let userData = localStorage.getItem(USER_DATA_KEY_IN_STORAGE);
  if (userData) {
    userData = JSON.parse(userData);
    return userData.userToken;
  }
  return null;
};

export async function getSections(payload) {
  const response = await HttpClient.get(`${BASE_API_URL}/${SECTIONS_API}`).then(
    (res) => res
  );
  return response;
}

export async function getStories(payload) {
  const response = await HttpClient.get(
    STORIES_API + "/?slug=video-list" + payload
  ).then((res) => res);
  return response;
}

export async function partnerAuth(payload) {
  const response = await HttpClient.post(
    `${SHEROES_MIDDLEWARE_BASE_PATH}/${PARTNER_AUTH_API}`,
    null,
    payload
  ).then((res) => res);
  return response;
}

export async function getProductCategories(slug) {
  const url = slug
    ? `${BASE_API_URL}/${PRODUCT_CATEGORIES_API}?slug=${slug}`
    : `${BASE_API_URL}/${PRODUCT_CATEGORIES_API}`;
  return await HttpClient.get(url).then((res) => res);
}

export async function getProductCollections(slug) {
  const url = slug
    ? `${BASE_API_URL}/${PRODUCT_COLLECTIONS_API}?slug=${slug}`
    : `${BASE_API_URL}/${PRODUCT_COLLECTIONS_API}`;
  return await HttpClient.get(url).then((res) => res);
}

export async function getProducts({
  categorySlug = null,
  start = 0,
  limit = 5,
}) {
  let url = ``;
  if (categorySlug) {
    url += `${getUrlQueryParamsPrefix(
      url
    )}product_categories.slug=${categorySlug}`;
  }
  url += `${getUrlQueryParamsPrefix(url)}_start=${start}&_limit=${limit}`;

  return await HttpClient.get(`${BASE_API_URL}/${PRODUCTS_API}${url}`).then(
    (res) => res
  );
}

export async function getProductsSearch({ query }) {
  let url = `${BASE_API_URL}/${PRODUCTS_API}?${query}`;

  return await HttpClient.get(url).then((res) => res);
}

export async function getProductDetail(productId) {
  return await HttpClient.get(
    `${BASE_API_URL}/${PRODUCTS_API}${productId}`
  ).then((res) => res);
}

export async function addProductToCart(payload) {
  const authToken = getAuthToken();

  const response = HttpClient.post(
    `${SHEROES_MIDDLEWARE_BASE_PATH}/${PRODUCTS_CART_API}`,
    null,
    payload,
    {
      "auth-token": authToken,
    }
  ).then((res) => res);
  return response;
}

export async function getCartDetail() {
  return HttpClient.get(
    `${SHEROES_MIDDLEWARE_BASE_PATH}/${PRODUCTS_CART_API}`,
    null,
    {
      "auth-token": getAuthToken(),
    }
  ).then((res) => res);
}

export async function getStoryCollections(slug) {
  const url = slug
    ? `${BASE_API_URL}/${STORY_COLLECTIONS_API}?slug=${slug}`
    : `${BASE_API_URL}/${STORY_COLLECTIONS_API}`;
  return HttpClient.get(url).then((res) => res);
}

export async function addProductToMyShop(product) {
  return HttpClient.post(
    `${SHEROES_MIDDLEWARE_BASE_PATH}/${PRODUCT_WISHLIST_API}`,
    null,
    { product },
    { "auth-token": getAuthToken() }
  ).then((res) => res);
}

export async function getMyShopProducts() {
  return HttpClient.get(
    `${SHEROES_MIDDLEWARE_BASE_PATH}/${PRODUCT_WISHLIST_API}`,
    null,
    { "auth-token": getAuthToken() }
  ).then((res) => res);
}

export async function removeProductFromMyShop(product) {
  return HttpClient.delete(
    `${SHEROES_MIDDLEWARE_BASE_PATH}/${PRODUCT_WISHLIST_API}${product.id}/`,
    null,
    { "auth-token": getAuthToken() }
  ).then((res) => res);
}

export async function removeItemFromCart(item) {
  const url = `${SHEROES_MIDDLEWARE_BASE_PATH}/${PRODUCTS_CART_API}${item.productId}/${item.variantId}`;
  return HttpClient.delete(url, null, { "auth-token": getAuthToken() }).then(
    (res) => res
  );
}

export async function productCheckoutAction(payload) {
  return HttpClient.post(
    `${SHEROES_MIDDLEWARE_BASE_PATH}/${PRODUCT_CHECKOUT_API}`,
    null,
    payload,
    { "auth-token": getAuthToken() }
  ).then((res) => res);
}

export async function updateProductQuantityInCart(item) {
  const url = `${SHEROES_MIDDLEWARE_BASE_PATH}/${PRODUCTS_CART_API}${item.productId}/${item.variantId}`;
  return HttpClient.patch(
    url,
    null,
    { orderQuantity: item.orderQuantity },
    { "auth-token": getAuthToken() }
  ).then((res) => res);
}

export async function userLogin(payload) {
  return HttpClient.post(
    `${SHEROES_MIDDLEWARE_BASE_PATH}/${LOGIN_API}`,
    null,
    payload
  ).then((res) => res);
}

export function registerUserEvent(payload, addUserType = false) {
  const apiUrl = addUserType ? `${USER_EVENT_API}?userType=coseller` : USER_EVENT_API
  return HttpClient.post(apiUrl, null, payload).then((res) => res);
}
