export const BASE_API_URL = "https://staging.shecobysheroes.com:1338";
export const SHEROES_MIDDLEWARE_BASE_PATH =
  "https://staging.shecobysheroes.com:8081";
// export const SHEROES_MIDDLEWARE_BASE_PATH = 'http://localhost:8080'
export const USER_EVENT_API =
  "https://backend.shoptype.com/user-event";

export const SECTIONS_API = "sections/";
export const STORIES_API = "stories/";
export const PARTNER_AUTH_API = "user/partner-auth/";
export const PRODUCTS_API = "products/";
export const PRODUCT_CATEGORIES_API = "product-categories/";
export const PRODUCT_COLLECTIONS_API = "product-collections/";
export const PRODUCTS_CART_API = `${PRODUCTS_API}cart/`;
export const STORY_COLLECTIONS_API = `collections/`;
export const PRODUCT_WISHLIST_API = `${PRODUCTS_API}wishlist/`;
export const PRODUCT_CHECKOUT_API = `${PRODUCTS_API}checkout/`;
export const LOGIN_API = `user/login/`;
