/* eslint-disable no-loop-func */
import React, { Component } from "react";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import Button from "@material-ui/core/Button";
import { toast } from "react-toastify";
import { isEmpty } from "lodash";

import { allSettled } from "../../helpers/utils";
import { white, lightGrey } from "../../colors/color";
import "./singleProductCopy.css";
import {
  getProductDetail,
  addProductToCart,
  getCartDetail,
} from "../../lib/api";
import Filter from "../../components/filter/Filter";
import {
  getProducts,
  updateProductQuantityInCart,
  getAuthToken,
  registerUserEvent,
} from "../../lib/api";
import Loader from "../../components/loader";
import { MEDIA_PROXY_URL } from "../../constants/global";

const styles = {
  singleProductMainBody: {
    height: window.innerHeight - 65,
    overflowY: "scroll",
    paddingBottom: "15vh",
  },
  title4VHText: { fontSize: "4vh", textAlign: "center" },
  footerContainer: {
    height: "16vh",
    background: "#FF4343",
    position: "fixed",
    bottom: "0px",
    width: "100%",
    borderRadius: "12px 12px 0px 0px",
  },
  footerContainerChild: {
    padding: "26px 46px 0px 46px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  joinTheCosellerContainer: (cosellClicked) => ({
    height: cosellClicked ? "6vh" : "14vh",
    display: "flex",
    flexDirection: "column",
    position: "fixed",
    bottom: "16vh",
    width: "100%",
    background: white,
  }),
  joinCosellerChild: (cosellClicked) => ({
    padding: cosellClicked ? "0px 30px" : "4px 30px 4px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
    width: "100%",
  }),
  joinTheCosellertext: {
    fontSize: "2.6vh",
    fontWeight: "bold",
    color: "#FF4343",
  },
  expandLessContainer: {
    width: "26px",
    height: "26px",
    background: "#FF4343",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: white,
  },
  becomeCosellerContainer: {
    padding: "0px 30px 0px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  becomeCosellerTextDiv: {
    fontSize: "2.4vh",
    display: "flex",
    flexDirection: "column",
  },
  topImageContainer: {
    position: "relative",
    background: "#a9a9a966",
    height: "30vh",
    padding: "0px 20p",
    marginLeft: "22px",
    marginRight: "22px",
    marginTop: "80px",
  },
  topImgLeftRightIconContainer: {
    position: "absolute",
    top: "20%",
    width: "100%",
    padding: "0px 8px",
    display: "flex",
    justifyContent: "space-between",
  },
  topImgBottomImgContainer: {
    position: "absolute",
    bottom: "0px",
    height: "12vh",
    width: "100%",
    display: "flex",
    alignItems: "center",
    padding: "0px 16px",
    overflowX: "scroll",
  },
  topImgBottomChildImg: {
    width: "14vw",
    background: "white",
    height: "8vh",
    borderRadius: "4px",
    margin: "4px",
    objectFit: "contain",
  },

  productTitleContainer: {
    display: "flex",
    flexDirection: "column",
    lineHeight: "4vh",
    margin: "10px 22px",
  },
  sizeSpan: {
    width: "50%",
    background: "#9e9e9e70",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  sizeSpanRight: {
    width: "20%",
    background: "#F44336",
    borderRadius: "1px 4px 4px 1px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
  },
  colourSpan: {
    width: "50%",
    background: "#ffc10769",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "5px",
    borderRadius: "4px",
  },
  colourSpanRight: {
    width: "20%",
    background: "#F44336",
    borderRadius: "1px 4px 4px 1px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
  },
  quantitySpanLeft: {
    width: "20%",
    background: "#9E9E9E",
    borderRadius: "4px 1px 1px 4px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
  },
  quantitySpanMiddle: {
    width: "30%",
    background: "#9e9e9e70",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  quantitySpanRight: {
    width: "20%",
    background: "#F44336",
    borderRadius: "1px 4px 4px 1px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
  },
};

class SingleProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageActiveStep: 0,
      productDetail: {},
      mediaStorage: {
        images: {},
        videos: {},
      },
      primaryImage: {
        id: "",
        mediaSrc: "",
        variantIds: [],
      },
      defaultVariant: { variantNameValue: { title: "Default Title" } },
      variantStorage: {},
      variantOptionTypeOptionValuesMapping: {},
      selectedVariantOptionTypes: [],
      selectedOptions: {},
      selectedVariant: {},
      productQuantity: 1,
      productTotalPrice: 0,
      similarProductsList: [],
      primaryCategory: {},
      cartItems: [],
      productExistingInCart: false,
      isFetchingData: true,
      loggedOut: false,
      selectedVariantArray: [],
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    if (!getAuthToken()) {
      this.setState((state) => ({
        ...state,
        loggedOut: true,
        isFetchingData: false,
      }));
    } else {
      this.fetchData(id);
    }
  }

  getUserId() {
    let { userToken } = this.props.auth;
    userToken = userToken.split(".");
    let userData = JSON.parse(atob(userToken[1]));
    const { userId } = userData;
    return userId;
  }

  registerUserEventClick() {
    const { shoptypeData } = this.state.productDetail;
    const payload = {
      deviceId: this.props.auth.deviceId,
      action: "product_view",
      productId: shoptypeData.id,
      userId: this.getUserId(),
      referer: window.location.href,
      url: window.location.href,
    };
    registerUserEvent(payload, true).then((res) => {
      // console.log(res, "register res..");
    });
  }

  getVariantFromSelectedOption = () => {
    // generate the key here and search it from variantStorage
    let variantNameArray = [];
    let variantName = "";
    let {
      selectedOptions,
      defaultVariant,
      variantOptionTypeOptionValuesMapping,
      variantStorage,
    } = this.state;
    let selectedVariant = {};
    const selectedVariantOptionTypes = Object.keys(
      variantOptionTypeOptionValuesMapping
    );
    if (selectedVariantOptionTypes && selectedVariantOptionTypes.length > 0) {
      selectedVariantOptionTypes.forEach((optionType) => {
        if (selectedOptions[optionType]) {
          variantNameArray.push(selectedOptions[optionType]);
        }
      });
      variantName = variantNameArray.join("/");
      selectedVariant = variantStorage[variantName];
    } else {
      selectedVariant = defaultVariant;
    }
    this.setState(
      (state) => ({
        ...state,
        selectedVariant: {
          ...state.selectedVariant,
          ...selectedVariant,
        },
      }),
      () => {
        // console.log(this.state.selectedVariant, variantName);
        // based on selectedVariantId and productId, see if product existing in cart
        const variantId = this.state.selectedVariant.id;
        const productId = this.state.productDetail.id;
        const itemMatched = this.state.cartItems.filter((item) => {
          return item.variantId === variantId && item.productId === productId;
        });

        const productQuantityData =
          itemMatched.length > 0
            ? {
                productExistingInCart: true,
                productQuantity: parseInt(itemMatched[0].orderQuantity),
              }
            : {
                productExistingInCart: false,
              };
        this.setState(
          (state) => ({
            ...state,
            ...productQuantityData,
          }),
          () => this.handleQuantityChange(this.state.productQuantity)
        );
      }
    );
  };

  filterSelected = ({ selectedFilters }) => {
    this.setState(
      (state) => ({
        ...state,
        selectedOptions: {
          ...state.selectedOptions,
          ...selectedFilters,
        },
      }),
      () => {
        console.log(this.state, "filter select state...");
        this.getVariantFromSelectedOption();
      }
    );
  };

  handleQuantityChange = (qty) => {
    if (this.state.selectedVariant.discountedPrice) {
      let total = Number(this.state.selectedVariant.discountedPrice) * qty;
      this.setState((state) => ({
        ...state,
        productQuantity: qty,
        productTotalPrice: total,
      }));
    }
  };

  fetchData = async (id) => {
    try {
      const response = await getProductDetail(id);
      const cartResponse = await getCartDetail();
      const productDetail = response.data;
      if (
        response.data.product_categories &&
        response.data.product_categories.length
      ) {
        const primaryCategory = response.data.product_categories[0];
        this.getSimilarProducts(primaryCategory);
      }
      this.setState(
        (state) => ({
          ...state,
          productDetail: {
            ...state.productDetail,
            ...productDetail,
          },
          cartItems:
            cartResponse.data && cartResponse.data.items
              ? cartResponse.data.items
              : [],
          isFetchingData: false,
        }),
        () => {
          let { productDetail } = this.state;
          let { shoptypeData: fetchedProductDetails } = productDetail;

          this.registerUserEventClick();

          // selectedOptions set to first value in the options list
          let { options, variants } = fetchedProductDetails;
          let obj = {};
          options.forEach((item) => {
            const key = item.name;
            const value = item.values[0];
            obj[key] = value;
          });

          this.setState({
            selectedOptions: { ...obj },
          });

          // selectedOptions end

          this.pushFetchedMediaDataToMediaStorage(
            fetchedProductDetails.primaryImageSrc || {},
            fetchedProductDetails.secondaryImageSrc || [],
            fetchedProductDetails.primaryVideoSrc || {}
          );

          this.pushFetchedVariantDataToVariantStorage(
            variants || [],
            options || []
          );
        }
      );
    } catch (err) {
      this.setState((state) => ({
        ...state,
        isFetchingData: false,
      }));
      console.log(err);
    }
    // getProductDetail(id).then((response) => {
    //   const productDetail = response.data;
    //   this.setState(
    //     {
    //       ...this.state,
    //       productDetail: {
    //         ...this.state.productDetail,
    //         ...productDetail,
    //       },
    //     },
    //     () => {
    //       let { productDetail } = this.state;
    //       let { shoptypeData: fetchedProductDetails } = productDetail;

    //       this.pushFetchedMediaDataToMediaStorage(
    //         fetchedProductDetails.primaryImageSrc || {},
    //         fetchedProductDetails.secondaryImageSrc || [],
    //         fetchedProductDetails.primaryVideoSrc || {}
    //       );

    //       this.pushFetchedVariantDataToVariantStorage(
    //         fetchedProductDetails.variants || [],
    //         fetchedProductDetails.options || []
    //       );
    //     }
    //   );
    // });
  };

  pushFetchedMediaDataToMediaStorage = (
    primaryImageElement,
    secondaryImageElements
  ) => {
    let { mediaStorage, primaryImage } = this.state;

    if (!isEmpty(primaryImageElement)) {
      mediaStorage.images[primaryImageElement.id] = {
        id: primaryImageElement.id,
        mediaSrc: primaryImageElement.imageSrc,
        variantIds: primaryImageElement.variantIds || [],
        primary: true,
      };

      primaryImage = {
        id: primaryImageElement.id,
        mediaSrc: primaryImageElement.imageSrc,
        variantIds: primaryImageElement.variantIds || [],
      };
    }
    if (!isEmpty(secondaryImageElements)) {
      secondaryImageElements.forEach((imageElement) => {
        mediaStorage.images[imageElement.id] = {
          id: imageElement.id,
          mediaSrc: imageElement.imageSrc,
          variantIds: imageElement.variantIds || [],
        };
      });
    }
    this.setState({ mediaStorage, primaryImage });
  };

  // Variant List hashmap
  pushFetchedVariantDataToVariantStorage = (variants, options) => {
    let {
      defaultVariant,
      variantOptionTypeOptionValuesMapping,
      variantStorage,
    } = this.state;

    const selectedVariantId = variants.length > 0 ? variants[0].id : null;
    const variantArrays = [];

    options.forEach((option, index) => {
      variantOptionTypeOptionValuesMapping[option.name] = {
        values: option.values,
        index: index,
      };
      variantArrays.push(option.values);
    });

    // all combos available
    let variantNameArrays =
      variantArrays.length > 0 ? this.cartesian(variantArrays) : [];

    let variantNames = variantNameArrays.map((v) => v.join("/"));

    // if variants from backend are lesser than all combos available
    if (variants.length !== variantNameArrays.length) {
      const comboLength =
        variantArrays.length > 0 ? variantNameArrays[0].length : 0;

      // sort by descending specificity (no of speicifc attributes(variantNameValue))
      variants = variants.sort(
        (a, b) =>
          (b.variantNameValue ? Object.keys(b.variantNameValue).length : 0) -
          (a.variantNameValue ? Object.keys(a.variantNameValue).length : 0)
      );

      for (let variant of variants) {
        const variantNameValues = variant.variantNameValue
          ? Object.keys(variant.variantNameValue)
          : [];
        // if all attributes are specified
        if (variantNameValues.length === comboLength) {
          let variantArray = [];
          let variantName = "";
          Object.keys(variantOptionTypeOptionValuesMapping).forEach(
            (optionType) => {
              const index =
                variantOptionTypeOptionValuesMapping[optionType].index;
              variantArray[index] = variant.variantNameValue[optionType];
            }
          );

          variantName = variantArray.join("/");
          const variantNameIndex = variantNames.indexOf(variantName);
          variantStorage[variantName] = variant;
          variantNameArrays.splice(variantNameIndex, 1);
          variantNames.splice(variantNameIndex, 1);
          continue;
        }

        // if only some attributes are specified
        if (
          variantNameValues.length < comboLength &&
          variantNameValues.length > 0
        ) {
          Object.keys(variantOptionTypeOptionValuesMapping).forEach(
            (optionType) => {
              let matchingVariantArrays = [];
              const index =
                variantOptionTypeOptionValuesMapping[optionType].index;
              if (variant.variantNameValue[optionType]) {
                matchingVariantArrays = matchingVariantArrays.filter(
                  (v) => v[index] === variant.variantNameValue[optionType]
                );
                matchingVariantArrays = matchingVariantArrays.concat(
                  variantNameArrays.filter(
                    (v) => v[index] === variant.variantNameValue[optionType]
                  )
                );
              }

              matchingVariantArrays.forEach((variantArray) => {
                const variantName = variantArray.join("/");
                const variantNameIndex = variantNames.findIndex(
                  (v) => v === variantName
                );
                variantStorage[variantName] = variant;
                variantNameArrays.splice(variantNameIndex, 1);
                variantNames.splice(variantNameIndex, 1);
              });
            }
          );

          continue;
        }

        // if no attributes are specified
        if (variantNameValues.length === 0) {
          variantNameArrays.forEach((variantNameArray) => {
            const variantName = variantNameArray.join("/");
            variantStorage[variantName] = variant;
          });
          variantNameArrays = [];
          variantNames = [];
        }
      }

      // Add remaining variants as unavailable (quantity = -1)
      if (variantNameArrays.length > 0) {
        variantNames.forEach((v) => {
          variantStorage[v] = { quantity: -1 };
        });
      }
    } else {
      // if variants from backend are equal to all combos available
      variants.forEach((variant) => {
        let variantNameArray = [];
        let variantName = "";
        Object.keys(variantOptionTypeOptionValuesMapping).forEach(
          (optionType) => {
            if (variant.variantNameValue[optionType]) {
              variantNameArray.push(variant.variantNameValue[optionType]);
            }
          }
        );

        variantName = variantNameArray.join("/");
        variantStorage[variantName] = variant;
      });
    }
    this.setState(
      {
        defaultVariant,
        variantOptionTypeOptionValuesMapping,
        variantStorage,
        selectedVariantId,
      },
      () => {
        // Add the first variant name to the selectedVariant property
        this.getVariantFromSelectedOption();
      }
    );
  };

  cartesian = (args) => {
    var r = [],
      max = args.length - 1;
    function helper(arr, i) {
      for (var j = 0, l = args[i].length; j < l; j++) {
        var a = arr.slice(0); // clone arr
        a.push(args[i][j]);
        if (i === max) r.push(a);
        else helper(a, i + 1);
      }
    }
    helper([], 0);
    return r;
  };

  componentWillReceiveProps(nextProps) {
    const newId = nextProps.match.params.id;
    if (this.state.productDetail.id !== newId) {
      getProductDetail(newId);
    }
    return null;
  }

  getSimilarProducts = (primaryCategory) => {
    getProducts({
      categorySlug: primaryCategory.slug,
      limit: 10,
    }).then((res) => {
      const list = res.data.filter((p) => p.id !== this.state.productDetail.id);
      this.setState({
        similarProductsList: list,
        primaryCategory: primaryCategory,
      });
    });
  };

  handleNextImgActiveStep = () => {
    this.setState((state) => ({
      imageActiveStep: state.imageActiveStep + 1,
    }));
  };

  handleBackImgActiveStep = () => {
    this.setState((state) => ({
      imageActiveStep: state.imageActiveStep - 1,
    }));
  };

  shareSubmit = async (e) => {
    const { shoptypeData } = this.state.productDetail;

    const imageTitle = `*${shoptypeData.title}* - ${shoptypeData.description}`;
    const imageSrc = shoptypeData.primaryImageSrc.imageSrc;
    let imageURL =
      "whatsapp://send?text=" + imageTitle + " " + encodeURIComponent(imageSrc);

    const images =
      shoptypeData.secondaryImageSrc && shoptypeData.secondaryImageSrc.length
        ? [shoptypeData.primaryImageSrc, ...shoptypeData.secondaryImageSrc]
        : [shoptypeData.primaryImageSrc];

    // Image List send
    let imageBlobs = await allSettled(
      images.map(async (item) => {
        let response = await fetch(MEDIA_PROXY_URL + item.imageSrc);
        return response.blob();
      })
    );

    let files = [];
    imageBlobs.forEach((response, index) => {
      if (response.status === "fulfilled") {
        files.push(
          new File([response.value], `share.jpg`, {
            type: response.value.type,
          })
        );
      }
    });

    console.log(files);

    if (navigator.canShare && navigator.canShare({ files: files })) {
      navigator
        .share({
          text: imageTitle,
          title: "Check this out!",
          files: files,
        })
        .then(() => console.log("Successful share"))
        .catch((error) => console.log("Error in sharing", error));
    } else {
      console.log(`system does not support sharing files.`);
      window.location.href = imageURL;
    }
  };

  render() {
    const { classes } = this.props;
    const {
      imageActiveStep,
      productDetail,
      productTotalPrice,
      isFetchingData,
      loggedOut,
    } = this.state;
    let carousalImages = productDetail.shoptypeData
      ? [productDetail.shoptypeData.primaryImageSrc]
      : [];
    if (
      productDetail.shoptypeData &&
      productDetail.shoptypeData.secondaryImageSrc
    ) {
      carousalImages = [
        ...carousalImages,
        ...productDetail.shoptypeData.secondaryImageSrc,
      ];
    }

    return (
      <div className={classes.singleProductMainBody}>
        {loggedOut ? (
          <div className="container mt100 text-center">
            <img
              style={{ width: "100%" }}
              src={require("../../assets/images/error-icon.png")}
              alt="error"
            />
            <p
              style={{
                fontSize: "2.2vh",
                fontWeight: "bold",
                color: "#4A4A4A",
              }}
            >
              You are not authenticated!
            </p>
          </div>
        ) : isFetchingData ? (
          <div
            style={{
              marginTop: "50%",
            }}
          >
            <Loader />
          </div>
        ) : (
          <>
            {carousalImages && carousalImages.length && (
              <div className={classes.topImageContainer}>
                <img
                  src={carousalImages[imageActiveStep].imageSrc}
                  id="img"
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "contain",
                  }}
                  alt={productDetail.title}
                />
                <div className={classes.topImgLeftRightIconContainer}>
                  <KeyboardArrowLeft
                    onClick={(e) => {
                      e.preventDefault();
                      if (imageActiveStep > 0) {
                        this.handleBackImgActiveStep();
                      }
                    }}
                    style={{ width: "2em", height: "2em", color: "white" }}
                  />
                  <KeyboardArrowRight
                    onClick={(e) => {
                      e.preventDefault();
                      if (imageActiveStep < carousalImages.length - 1) {
                        this.handleNextImgActiveStep();
                      }
                    }}
                    style={{ width: "2em", height: "2em", color: "white" }}
                  />
                </div>
                {/* <div className={classes.topImgBottomImgContainer}>
                  {carousalImages.map((step, index) => (
                    <img
                      className={classes.topImgBottomChildImg}
                      src={step.imageSrc}
                      alt={step.id}
                      key={step.id}
                    />
                  ))}
                </div> */}
              </div>
            )}

            {productDetail && (
              <div>
                <div className={classes.productTitleContainer}>
                  <span
                    style={{
                      fontSize: "2.7vh",
                      fontWeight: "bold",
                      color: "#4A4A4A",
                    }}
                  >
                    {productDetail.title}
                  </span>
                  <div>
                    {/* Strike through price and show a discounted price */}
                    {productDetail.shoptypeData &&
                    this.state.selectedVariant &&
                    this.state.selectedVariant.discountedPrice ? (
                      <div style={{ float: "left" }}>
                        <span style={{ fontSize: "2vh" }}>
                          <span style={{ color: "#FF4343" }}>MRP: </span>{" "}
                          {productDetail.shoptypeData.currency}{" "}
                          <span>{this.state.selectedVariant.price}</span>{" "}
                        </span>
                        <div style={{ fontSize: "2vh" }}>
                          <span style={{ color: "#FF4343" }}>
                            SHECO Partner Price:{" "}
                          </span>
                          {productDetail.shoptypeData.currency} {""}
                          {this.state.selectedVariant.discountedPrice}
                        </div>
                        <div style={{ fontSize: "2vh" }}>
                          <span style={{ color: "#FF4343" }}>Commission: </span>
                          {productDetail.shoptypeData.currency}{" "}
                          {this.state.selectedVariant.price -
                            this.state.selectedVariant.discountedPrice}
                        </div>
                      </div>
                    ) : null}
                    {/* whatsapp logo right aligned */}
                    <button
                      onClick={(e) => this.shareSubmit(e)}
                      className="btn btn-outline-secondary"
                      style={{ float: "right", marginRight: "10px" }}
                    >
                      <img
                        src={require("../../assets/images/Whatsapp-Logo.svg")}
                        alt="whatsapp"
                        style={{
                          height: "3vh",
                          width: "auto",
                          cursor: "pointer",
                          marginRight: "2px",
                        }}
                      />
                      Share
                    </button>
                  </div>
                </div>

                {/* Filter component */}
                {productDetail && productDetail.shoptypeData ? (
                  <Filter
                    options={productDetail.shoptypeData.options}
                    selectedFilter={this.state.selectedOptions}
                    handleFilterSelect={this.filterSelected}
                    currentVariantAvailableQty={
                      this.state.selectedVariant.quantity
                    }
                    handleQuantityChange={this.handleQuantityChange}
                    initialQuantity={this.state.productQuantity}
                  />
                ) : (
                  ""
                )}
                <div
                  style={{
                    margin: "14px 22px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {productDetail &&
                  productDetail.shoptypeData &&
                  productDetail.shoptypeData.variants &&
                  productDetail.shoptypeData.variants[0].quantity < 5 ? (
                    productDetail.shoptypeData.variants[0].quantity === 0 ? (
                      <div style={{ color: "red" }}>Out of stock</div>
                    ) : (
                      <div style={{ color: "green" }}>
                        Less than 5 pieces left
                      </div>
                    )
                  ) : (
                    <div style={{ color: "green" }}>In Stock</div>
                  )}
                  <span
                    style={{
                      fontSize: "2.2vh",
                      fontWeight: "bold",
                      color: "#4A4A4A",
                    }}
                  >
                    Product Description
                  </span>
                  <div
                    style={{
                      color: lightGrey,
                      whiteSpace: "pre-wrap",
                      fontWeight: "500",
                      fontSize: "16px",
                    }}
                  >
                    {productDetail.description}
                  </div>
                </div>
                {this.state.similarProductsList.length > 0 ? (
                  <div style={{ margin: "14px 0px 14px 22px" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "2.2vh",
                          fontWeight: "bold",
                          color: "#4A4A4A",
                        }}
                      >
                        Similar Products
                      </span>
                      <span
                        style={{ marginRight: "22px", color: "red" }}
                        onClick={(e) => {
                          e.preventDefault();
                          this.props.history.push(
                            `/products/?category=${this.state.primaryCategory.slug}`
                          );
                        }}
                      >
                        View All
                      </span>
                    </div>
                    <div className="similarProduct">
                      {this.state.similarProductsList.map((data, index) => (
                        <div
                          className="similarProductListItem"
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.history.push(`/products/${data.id}`);
                            window.location.reload(true);
                          }}
                          key={index}
                        >
                          <img
                            src={`${data.shoptypeData.primaryImageSrc.imageSrc}`}
                            alt={data.title}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <span></span>
                )}
              </div>
            )}

            {/* <ShopCard />
              <ShopCard />
              <ShopCard />
              <div style={styles.joinTheCosellerContainer(this.state.cosellClicked)}>
                <div style={styles.joinCosellerChild(this.state.cosellClicked)}>
                  <span className={classes.joinTheCosellertext}>
                    Be a Sheco Partner on Sheroes!
                  </span>
                  {this.state.cosellClicked ? (
                    <div
                      className={classes.expandLessContainer}
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({ cosellClicked: false });
                      }}
                    >
                      <ExpandLess />
                    </div>
                  ) : (
                    <Cancel
                      style={{ color: "#FF4343" }}
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({ cosellClicked: true });
                      }}
                    />
                  )}
                </div>
                {this.state.cosellClicked ? null : (
                  <div className={classes.becomeCosellerContainer}>
                    <div className={classes.becomeCosellerTextDiv}>
                      <span>Become a coseller!</span>
                      <span>Earn as your shop </span>
                    </div>
                    <Fab
                      style={{
                        padding: "0px 24px",
                        height: "32px",
                        backgroundColor: "#FF4343",
                        color: white,
                        fontWeight: "bold",
                        width: "44%",
                      }}
                      variant="extended"
                      size="small"
                      color="primary"
                      aria-label="Add"
                    >
                      COSELL
                    </Fab>
                  </div>
                )}
              </div> */}
            <div className={classes.footerContainer}>
              <div className={classes.footerContainerChild}>
                {productDetail.shoptypeData && (
                  <span
                    style={{
                      fontSize: "24px",
                      fontWeight: "bold",
                      color: "white",
                    }}
                  >
                    {productDetail.shoptypeData.currency} {productTotalPrice}
                  </span>
                )}
                <Button
                  variant="contained"
                  style={{
                    padding: "12px 12px",
                    color: "#4A4A4A",
                    backgroundColor: white,
                    fontWeight: "700",
                    fontSize: "18px",
                  }}
                  onClick={() => this.addToCart()}
                  disabled={!this.state.productQuantity}
                >
                  ADD TO CART
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
  multiply = (num1, num2) => {
    return num1 * num2;
  };
  increaseProductQuantity = () => {
    this.setState((state) => ({
      productQuantity: state.productQuantity + 1,
    }));

    this.setState((state) => ({
      ...state,
      productTotalPrice:
        state.productDetail.shoptypeData.variants[0].price *
        state.productQuantity,
    }));
  };
  decreaseProductQuantity = () => {
    this.setState((state) => ({
      productQuantity: state.productQuantity - 1,
    }));
    this.setState((state) => ({
      ...state,
      productTotalPrice: this.multiply(
        state.productDetail.shoptypeData.variants[0].price,
        state.productQuantity
      ),
    }));
  };

  addToCart() {
    const { shoptypeData, id } = this.state.productDetail;
    const {
      selectedVariant,
      productQuantity,
      productExistingInCart,
    } = this.state;

    if (productExistingInCart) {
      // Call the patch API
      const variantId = selectedVariant.id;
      updateProductQuantityInCart({
        variantId,
        productId: id,
        orderQuantity: productQuantity,
      })
        .then((res) => {
          this.props.history.push("/cart");
        })
        .catch((err) => {
          console.log("add to cart error: ", err);
        });
    } else {
      const payload = {
        product: shoptypeData,
        productId: id,
        variantId: selectedVariant.id,
        orderQuantity: productQuantity,
      };
      addProductToCart(payload)
        .then((res) => {
          toast.success("Item added to your cart");
          this.props.history.push("/cart");
        })
        .catch((err) => {
          toast.error("Something went wrong! Please try again.");
          console.log("add to cart error: ", err);
        });
    }
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(SingleProduct));
